import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/common/button';
import ImageAsBackground from 'components/common/image-as-background';

import './guide.scss';

class PostGuide extends Component {
  static propTypes = {
    anchorName: PropTypes.string.isRequired,
    guide: PropTypes.object,
  };

  handleDownloadClick = () => {
    const { attachment } = this.props.guide || {};

    if (attachment) {
      window.open(attachment.file.url);
    }
  };

  render() {
    const { anchorName, guide } = this.props;

    if (!guide) {
      return <></>;
    }

    const { title, backgroundImage, description, buttonCaption } = guide;

    return (
      <>
        <a href={`#${anchorName}`} className="hide-link-decoration">
          <span id={anchorName} className="heading-anchor" />
          <h1>Cheat Sheet</h1>
        </a>
        <div className="post-page_guide">
          <div className="post_page_guide_overlay" />
          <ImageAsBackground image={backgroundImage.fluid} />
          <h3 className="post-page_guide_title">{title}</h3>
          <p
            className="post-page_guide_description"
            dangerouslySetInnerHTML={{
              __html: ((description || {}).childMarkdownRemark || {}).html,
            }}
          />
          <Button
            className="post-page_guide_button"
            onClick={this.handleDownloadClick}
          >
            {buttonCaption}
          </Button>
        </div>
      </>
    );
  }
}

export default PostGuide;
