import React from 'react';
import { Link } from 'gatsby';

import Img from 'gatsby-image';

import './read-next.scss';

function PostReadNext({ items }) {
  if (!items || !items.length) {
    return <></>;
  }

  return (
    <div className="post-page_read-next">
      <h2 className="post-page_read-next_title">Read Next</h2>
      <div className="d-flex flex-column flex-lg-row">
        {items.map(item => (
          <PostItem
            key={item.id}
            categories={item.categories}
            name={item.title}
            image={(item.headerImage || {}).fluid}
            linkUrl={`/resource-library/${item.slug}`}
          >
            {((item.description || {}).childMarkdownRemark || {}).excerpt}
          </PostItem>
        ))}
      </div>
    </div>
  );
}

function PostItem({ categories, name, children, image, linkUrl }) {
  return (
    <Link to={linkUrl} className="post-page_read-next_post">
      {image && <Img fluid={image} className="post-page_read-next_image" />}

      <div className="post-page_read-next_gradient" />

      <div className="post-page_read-next_post_content">
        <div className="d-flex justify-content-between">
          <div className="post-page_read-next_tags-container">
            {categories.map(category => (
              <div key={category.slug} className="post-page_read-next_tag">
                {(category || {}).name}
              </div>
            ))}
          </div>
        </div>

        <span className="post-page_read-next_name">{name}</span>
        <div className="post-page_read-next_description">{children}</div>
      </div>
    </Link>
  );
}

export default PostReadNext;
