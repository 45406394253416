const getSlug = require('slug');
const readingTime = require('reading-time');

const SCREEN_SIZE_SM = 576;

function getReadTimeCalculator() {
  const memo = {};

  return function(contentId, contentText) {
    const found = memo[contentId];

    if (found) {
      return found;
    }

    const newValue = readingTime(contentText);

    if (contentId) {
      memo[contentId] = newValue;
    }

    return newValue;
  };
}

function getIsXSDevice() {
  return typeof window !== 'undefined' && window.innerWidth < SCREEN_SIZE_SM;
}

function extractAndAnchorHeaders(rawHtml) {
  const headers = [];
  const mask = /<h1>(.+?)<\/h1>/gi;

  const content = rawHtml.replace(mask, (match, text) => {
    const slug = getSlug(text, { lower: true });

    if (slug) {
      headers.push({
        text,
        slug,
      });
    }

    return `<a href="#${slug}" class="hide-link-decoration"><span id="${slug}" class="heading-anchor"></span> ${match}</a>`;
  });

  return {
    headers,
    content,
  };
}

module.exports = {
  readTimeCalculator: getReadTimeCalculator(),
  getIsXSDevice,
  extractAndAnchorHeaders,
};
