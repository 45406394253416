import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import queryString from 'query-string';

// lib
import { extractAndAnchorHeaders } from 'lib/utils';
import ServiceBot from 'components/service-bot';
import trackPageVisit from 'lib/track-page-visit';

// components
import Layout from 'components/common/layout';
import Header from 'components/common/header';
import ImageAsBackground from 'components/common/image-as-background';
import SubscribeFooter from 'components/common/subscribe-section';
import ReadNext from 'components/post/read-next';
import Sidebar from 'components/post/sidebar';
import FrequentlyAskedQuestions from 'components/post/frequently-asked-questions';
import Resources from 'components/post/resources';
import Guide from 'components/post/guide';
import Teaser1EssentialLoginNeeded from 'components/resource-library/teasers/teaser-1-essential-login-needed';
import Teaser2ProLoginNeeded from 'components/resource-library/teasers/teaser-2-pro-login-needed';
import Teaser3ProNeeded from 'components/resource-library/teasers/teaser-3-pro-needed';
import Teaser4Expired from 'components/resource-library/teasers/teaser-4-expired';
import PricingPlansModal from 'components/common/pricing-plans-modal';

// styles
import 'stylesheets/post/index.scss';

// constants
import {
  SUBSCRIBE_PLAN_FREE,
  SUBSCRIBE_PLAN_ESSENTIAL,
  SUBSCRIBE_PLAN_PRO,
  ESSENTIAL_PLAN,
  PRO_PLAN,
} from '../constants/servicebot_plans';

const STUB_ELEMENT_SIGN = '[Stub Element]';

const FAQ_ANCHOR_NAME = 'post-faq';
const RESOURCES_ANCHOR_NAME = 'post-resources';
const GUIDE_ANCHOR_NAME = 'post-guide';

const HEADER_HEIGHT = 52;
const TOP_SECTION_HEIGHT = 406;
const BOTTOM_SECTION_HEIGHT = 484;
const SIDEBAR_ADDITIONAL_OFFSET = 20;

const ACCESS_ALLOWED = 1;

const ACCESS_ESSENTIAL_LOGIN_NEEDED = 21;
const ACCESS_ESSENTIAL_EXPIRED = 22;

const ACCESS_PRO_LOGIN_NEEDED = 31;
const ACCESS_PRO_NEEDED = 32;
const ACCESS_PRO_EXPIRED = 33;

// component class
class ResourceLibraryPostPage extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  postContentRef = createRef();
  sidebarRef = createRef();

  state = {
    isClientSide: false,
    isSidebarFixedToTop: false,
    isSidebarFixedToBottom: false,
    selectPlanModalData: null,
    subscribeState: null,
  };

  componentDidMount() {
    const postContentContainer = this.postContentRef.current;

    const postContentImages = postContentContainer.querySelectorAll(
      '.gatsby-resp-image-image',
    );

    [].forEach.call(postContentImages, image => {
      image.addEventListener('load', this.handleImageLoad);
    });

    window.addEventListener('scroll', this.handleScrollEvent);

    ServiceBot.on('auth:change', this.handleSubscribedStateChange);

    this.handleSubscribedStateChange(ServiceBot.credentials);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollEvent);
    ServiceBot.off('auth:change', this.handleSubscribedStateChange);
  }

  checkSubscribedState = async authToken => {
    const { slug: subscribePlanSlug } =
      this.props.data.contentfulResourceLibrary.subscribePlan || {};

    // Free plan
    if (!subscribePlanSlug || subscribePlanSlug === SUBSCRIBE_PLAN_FREE) {
      return { subscribeState: ACCESS_ALLOWED };
    }

    //console.log('TOKEN DATA', authToken);

    // Essential plan
    if (subscribePlanSlug === SUBSCRIBE_PLAN_ESSENTIAL) {
      if (!authToken) {
        return { subscribeState: ACCESS_ESSENTIAL_LOGIN_NEEDED };
      }

      if (
        !ServiceBot.getIsSubscribeToPlan(authToken, [ESSENTIAL_PLAN, PRO_PLAN])
      ) {
        return { subscribeState: ACCESS_ESSENTIAL_EXPIRED };
      }

      // if (!ServiceBot.getIsSubscribePaid(authToken)) {
      //   return { subscribeState: ACCESS_ESSENTIAL_EXPIRED };
      // }

      return { subscribeState: ACCESS_ALLOWED };
    }

    // Pro plan
    if (subscribePlanSlug === SUBSCRIBE_PLAN_PRO) {
      if (!authToken) {
        return { subscribeState: ACCESS_PRO_LOGIN_NEEDED };
      }

      if (!ServiceBot.getIsSubscribeToPlan(authToken, [PRO_PLAN])) {
        return { subscribeState: ACCESS_PRO_NEEDED };
      }

      if (!ServiceBot.getIsSubscribePaid(authToken)) {
        return { subscribeState: ACCESS_PRO_EXPIRED };
      }

      return { subscribeState: ACCESS_ALLOWED };
    }

    return {};
  };

  getNextPosts() {
    const { pageContext, location } = this.props;
    const { allPosts, id } = pageContext;
    const { category: activeCategorySlug } = queryString.parse(location.search);

    let postsList = allPosts;

    if (activeCategorySlug) {
      postsList = postsList.filter(({ categories }) =>
        categories.find(({ slug }) => slug === activeCategorySlug),
      );
    }

    const currentPostIndex = postsList.findIndex(post => id === post.id);

    const nextPages = postsList.slice(
      currentPostIndex + 1,
      currentPostIndex + 3,
    );

    return nextPages;
  }

  omitStubElements(collection, fieldName) {
    if (!collection) {
      return null;
    }

    const items = []
      .concat(collection)
      .filter(item => item[fieldName] !== STUB_ELEMENT_SIGN);

    return items.length ? items : null;
  }

  handleSubscribedStateChange = token => {
    if (token === undefined) {
      return;
    }

    this.checkSubscribedState(token).then(subscribeState => {
      this.setState({ isClientSide: true, ...subscribeState });
    });
  };

  handleScrollEvent = e => {
    const { scrollingElement } = e.target;
    const { isSidebarFixedToTop } = this.state;

    const sidebarElem = this.sidebarRef.current;

    const sidebarHeight = sidebarElem.offsetHeight;

    const sidebarPositionTop =
      scrollingElement.scrollTop + HEADER_HEIGHT + SIDEBAR_ADDITIONAL_OFFSET;
    const isScrolledToBottom =
      document.body.offsetHeight - (sidebarPositionTop + sidebarHeight) <=
      BOTTOM_SECTION_HEIGHT;

    const offset = scrollingElement.scrollTop - TOP_SECTION_HEIGHT;

    let newState = null;

    if (isScrolledToBottom) {
      newState = { isSidebarFixedToTop: false, isSidebarFixedToBottom: true };
    } else if (offset > -SIDEBAR_ADDITIONAL_OFFSET && !isSidebarFixedToTop) {
      newState = { isSidebarFixedToTop: true, isSidebarFixedToBottom: false };
    } else if (offset <= -SIDEBAR_ADDITIONAL_OFFSET && isSidebarFixedToTop) {
      newState = { isSidebarFixedToTop: false, isSidebarFixedToBottom: false };
    }

    if (newState) {
      this.setState(newState);
    }
  };

  handleImageLoad = ({ target }) => {
    target.classList.add('gatsby-resp-image-image--loaded');
  };

  handleChooseProPlanClick = () => {
    const selectPlanModalData = {
      plan: PRO_PLAN,
      priceId: PRO_PLAN.prices[0].id,
    };

    this.setState({ selectPlanModalData });
  };

  handlePlanModalClose = () => {
    this.setState({ selectPlanModalData: null });
  };

  handlePlanSelected = priceId => {
    this.handlePlanModalClose();

    this.props.navigate(`/subscribe/checkout?payment_id=${priceId}`);
  };

  render() {
    const { pageContext, data, navigate } = this.props;
    const {
      isClientSide,
      isSidebarFixedToTop,
      isSidebarFixedToBottom,
      subscribeState,
      selectPlanModalData,
    } = this.state;

    const { title, categories, headerImage, id } = pageContext;
    const { fluid: image } = headerImage || {};
    const pageImage = (image || {}).src;

    const {
      showCommunityQuestionTeaser,
      faq: rawFaq,
      resources: rawResources,
      guide: rawGuide,
      content,
      demoContent,
      hideTableOfContents,
      description,
    } = data.contentfulResourceLibrary;
    const { html } = (content || {}).childMarkdownRemark;
    const { html: demoHtml } = (demoContent || {}).childMarkdownRemark || {};
    const nextPosts = this.getNextPosts();

    const descriptionExcerpt = ((description || {}).childMarkdownRemark || {})
      .excerpt;

    const {
      headers: contentHeaders,
      content: anchoredContent,
    } = extractAndAnchorHeaders(html);

    const faq = this.omitStubElements(rawFaq, 'question');
    const resources = this.omitStubElements(rawResources, 'name');
    const guide =
      !!rawGuide && rawGuide.title !== STUB_ELEMENT_SIGN ? rawGuide : null;

    const isGuideVisible = isClientSide && !!guide;
    const isFaqVisible =
      isClientSide && (!!(faq || {}).length || showCommunityQuestionTeaser);
    const isResourcesVisible = isClientSide && resources && resources.length;

    const sidebarItems = [...contentHeaders];

    if (isGuideVisible) {
      sidebarItems.push({
        text: 'Cheat Sheet',
        slug: GUIDE_ANCHOR_NAME,
      });
    }

    if (isResourcesVisible) {
      sidebarItems.push({
        text: 'Resources',
        slug: RESOURCES_ANCHOR_NAME,
      });
    }

    if (isFaqVisible) {
      sidebarItems.push({
        text: 'Community Questions',
        slug: FAQ_ANCHOR_NAME,
      });
    }

    const sideBarClassNames = classNames({
      'post-page_sidebar--fixed-to-top': isSidebarFixedToTop,
      'post-page_sidebar--fixed-to-bottom': isSidebarFixedToBottom,
    });

    return (
      <Layout
        footerClassName="post-page_footer"
        askNowPageIdentifier={id}
        title={title}
        pageDescription={descriptionExcerpt}
        pageImageSrc={pageImage}
        showAskNow
      >
        <main>
          {!!selectPlanModalData && (
            <PricingPlansModal
              pricingPlans={selectPlanModalData.plan}
              activePriceId={selectPlanModalData.priceId}
              onClose={this.handlePlanModalClose}
              onSubmit={this.handlePlanSelected}
            />
          )}

          <div className="post-page_section-top">
            <div className="post-page_section-top_gradient" />

            {image && <ImageAsBackground image={image} />}

            <Header className="post-page_header" styleType="dark" showLogo />

            <div className="post-page_section-top_content">
              <div className="d-flex justify-content-between">
                <div className="post-page_tags-container">
                  {categories.map(category => (
                    <div key={category.slug} className="post-page_tag">
                      {(category || {}).name}
                    </div>
                  ))}
                </div>
              </div>
              <h1 className="post-page_title">{title}</h1>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-start position-relative">
            <ContentOverlay
              sidebarItems={sidebarItems}
              isSidebarFixedToTop={isSidebarFixedToTop}
              isSidebarFixedToBottom={isSidebarFixedToBottom}
            >
              <Sidebar
                ref={this.sidebarRef}
                className={sideBarClassNames}
                items={sidebarItems}
                hide={hideTableOfContents}
              />
            </ContentOverlay>

            <div className="post-page_main">
              <div ref={this.postContentRef} className="post-page_content">
                <AccessOverlay
                  subscribeState={subscribeState}
                  demoHtml={demoHtml}
                  navigate={navigate}
                  onChooseProPlanClick={this.handleChooseProPlanClick}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: anchoredContent,
                    }}
                  />

                  <ClientSideOverlay isClient={isClientSide}>
                    <Guide anchorName={GUIDE_ANCHOR_NAME} guide={guide} />

                    <Resources
                      anchorName={RESOURCES_ANCHOR_NAME}
                      items={resources}
                    />

                    <FrequentlyAskedQuestions
                      anchorName={FAQ_ANCHOR_NAME}
                      items={faq}
                      showPlaceholder={showCommunityQuestionTeaser}
                    />
                  </ClientSideOverlay>
                </AccessOverlay>
              </div>

              <ReadNext items={nextPosts} />
            </div>
          </div>
        </main>
        <SubscribeFooter />
      </Layout>
    );
  }
}

function AccessOverlay({
  subscribeState,
  demoHtml,
  onChooseProPlanClick,
  children,
}) {
  if (subscribeState === ACCESS_ALLOWED) {
    return children;
  }

  return (
    <>
      <div
        className="post-page_demo-content"
        dangerouslySetInnerHTML={{
          __html: demoHtml,
        }}
      />

      {(() => {
        switch (subscribeState) {
          case ACCESS_ESSENTIAL_LOGIN_NEEDED:
            return <Teaser1EssentialLoginNeeded />;

          case ACCESS_PRO_LOGIN_NEEDED:
            return (
              <Teaser2ProLoginNeeded onChoosePlanClick={onChooseProPlanClick} />
            );

          case ACCESS_PRO_NEEDED:
            return <Teaser3ProNeeded />;

          case ACCESS_ESSENTIAL_EXPIRED:
            return <Teaser4Expired />;

          case ACCESS_PRO_EXPIRED:
            return <Teaser4Expired />;

          default:
            return <></>;
        }
      })()}
    </>
  );
}

function ContentOverlay({
  sidebarItems,
  isSidebarFixedToTop,
  isSidebarFixedToBottom,
  children,
}) {
  const rootClassNames = classNames(
    'd-flex justify-content-center align-items-start post-page_content-overlay',
    {
      'post-page_content-overlay--fixed-to-top': isSidebarFixedToTop,
      'post-page_content-overlay--fixed-to-bottom': isSidebarFixedToBottom,
    },
  );

  return (
    <div className={rootClassNames}>
      {children}
      <div className="post-page_content-overlay_space" />
    </div>
  );
}

function ClientSideOverlay({ isClient, children }) {
  if (!isClient) {
    return <></>;
  }

  return children;
}

export const pageQuery = graphql`
  query postContentQuery($id: String) {
    contentfulResourceLibrary(id: { eq: $id }) {
      description {
        childMarkdownRemark {
          excerpt(pruneLength: 140)
        }
      }
      hideTableOfContents
      subscribePlan {
        slug
      }
      showCommunityQuestionTeaser
      demoContent {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      faq {
        id
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
      resources {
        id
        name
        description {
          childMarkdownRemark {
            html
          }
        }
        linkUrl
      }
      guide {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonCaption
        backgroundImage {
          fluid(maxWidth: 902) {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
        attachment {
          file {
            url
          }
        }
      }
    }
  }
`;

export default trackPageVisit(ResourceLibraryPostPage);
