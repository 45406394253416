import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './text-spoiler.scss';

import AngleRightSvg from 'images/angle.svg';

class TextSpoiler extends Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
  };

  state = {
    isOpen: false,
  };

  handleTitleClick = () => {
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { title, text, className } = this.props;
    const { isOpen } = this.state;
    const spoilerClassNames = classNames('text-spoiler', className, {
      'text-spoiler--open': isOpen,
    });

    const contentHeight = isOpen ? 'auto' : 0;

    return (
      <div className={spoilerClassNames}>
        <div onClick={this.handleTitleClick} className="text-spoiler_title">
          {title}
          <span className="text-spoiler_icon">
            <AngleRightSvg />
          </span>
        </div>
        <AnimateHeight height={contentHeight}>
          <div
            className="text-spoiler_content"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </AnimateHeight>
      </div>
    );
  }
}

export default TextSpoiler;
