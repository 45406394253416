import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// vendor components
import Transition from 'react-transition-group/Transition';
import Textarea from 'react-textarea-autosize';

// images
import SendIcon from 'images/send.svg';
import CloseIcon from 'images/close-bold.svg';
import CheckIcon from 'images/check.svg';

// styles
import './ask-now-form.scss';

const INTRODUCTION_STEP = 'introduction';
const SUCCESS_STEP = 'success';
const EMAIL_STEP = 'email';
const MESSAGE_STEP = 'message';
const CLOSED_STEP = 'closed';

const SHOW_INTRODUCTION_TIMEOUT = 5000;
const CLOSE_INTRODUCTION_TIMEOUT = 4000;
const CLOSE_INTRODUCTION_BY_SCROLL_TIMEOUT = 1500;
const CLOSE_SUCCESS_TIMEOUT = 3000;

class AskNowForm extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isSendInProgress: PropTypes.bool,
    isSentSuccess: PropTypes.bool,
    onFieldChange: PropTypes.func.isRequired,
    emailValue: PropTypes.string,
    messageValue: PropTypes.string,
    onOpen: PropTypes.func.isRequired,
    pageIdentifier: PropTypes.string,
    mobileOnly: PropTypes.bool,
  };

  closeSuccessTimeout = null;
  closeIntroductionTimeout = null;
  messageInputRef = createRef();
  emailInputRef = createRef();
  isIntroductionStepCanBeClosed = false;

  state = {
    currentStep: CLOSED_STEP,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);

    const pageWasVisitedBefore = this.getPageWasVisitedBefore();

    if (!pageWasVisitedBefore) {
      const localStoreKey = this.getLocalStoreKey();

      localStorage.setItem(localStoreKey, 'true');

      this.setShowIntroductionTimeout();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);

    clearTimeout(this.closeSuccessTimeout);
    clearTimeout(this.closeIntroductionTimeout);
    clearTimeout(this.closeIntroductionByScrollTimeout);
    clearTimeout(this.showIntroductionTimeout);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errors, isSentSuccess } = nextProps;

    const newState = {};

    if (errors.message) {
      newState.currentStep = MESSAGE_STEP;
    }

    if (isSentSuccess) {
      newState.currentStep = SUCCESS_STEP;
      this.setCloseSuccessTimeout();
    }

    this.setState(newState);
  }

  setShowIntroductionTimeout() {
    this.showIntroductionTimeout = setTimeout(() => {
      this.setState({
        currentStep: INTRODUCTION_STEP,
      });
      this.setCloseIntroductionTimeout();
      this.setCloseIntroductionByScrollTimeout();
    }, SHOW_INTRODUCTION_TIMEOUT);
  }

  setCloseIntroductionByScrollTimeout() {
    this.closeIntroductionByScrollTimeout = setTimeout(() => {
      this.isIntroductionStepCanBeClosed = true;
    }, CLOSE_INTRODUCTION_BY_SCROLL_TIMEOUT);
  }

  setCloseIntroductionTimeout() {
    this.closeIntroductionTimeout = setTimeout(() => {
      const { currentStep } = this.state;

      if (currentStep === INTRODUCTION_STEP) {
        this.setState({
          currentStep: CLOSED_STEP,
        });
      }
    }, CLOSE_INTRODUCTION_TIMEOUT);
  }

  setCloseSuccessTimeout = () => {
    const { onOpen } = this.props;

    this.closeSuccessTimeout = setTimeout(() => {
      this.setState({
        currentStep: MESSAGE_STEP,
      });
      onOpen();
    }, CLOSE_SUCCESS_TIMEOUT);
  };

  getPageWasVisitedBefore = () => {
    const localStoreKey = this.getLocalStoreKey();

    return localStorage.getItem(localStoreKey) === 'true';
  };

  getLocalStoreKey = () => {
    const { pageIdentifier } = this.props;

    return `${pageIdentifier}-was-visited`;
  };

  getStepErrorMessage = () => {
    const { currentStep } = this.state;
    const { errors } = this.props;

    if (errors && currentStep === EMAIL_STEP) {
      return errors.email;
    }

    if (errors && currentStep === MESSAGE_STEP) {
      return errors.message;
    }

    return null;
  };

  handleWindowScroll = () => {
    const { currentStep } = this.state;

    if (
      currentStep === INTRODUCTION_STEP &&
      this.isIntroductionStepCanBeClosed
    ) {
      this.setState({
        currentStep: CLOSED_STEP,
      });
    }
  };

  handleMessageFormSubmit = e => {
    e.preventDefault();

    this.setState({
      currentStep: EMAIL_STEP,
    });
  };

  handleEmailFormSubmit = async e => {
    e.preventDefault();
    const { onSubmit } = this.props;

    await onSubmit();
  };

  handleOpenClick = () => {
    const { onOpen } = this.props;

    clearTimeout(this.showIntroductionTimeout);

    this.setState(
      {
        currentStep: MESSAGE_STEP,
      },
      onOpen,
    );
  };

  handleCancelClick = () => {
    this.setState({
      currentStep: CLOSED_STEP,
    });
  };

  handleMessageStepEntered = () => {
    const { currentStep } = this.state;

    if (currentStep === MESSAGE_STEP) {
      this.messageInputRef.current.focus();
    }
  };

  handleEmailStepEntered = () => {
    this.emailInputRef.current.focus();
  };

  handleMessageFieldFocus = e => {
    const { currentStep } = this.state;

    if (currentStep === INTRODUCTION_STEP) {
      this.setState({
        currentStep: MESSAGE_STEP,
      });
    }
  };

  render() {
    const {
      isVisible,
      emailValue,
      messageValue,
      onFieldChange,
      mobileOnly,
    } = this.props;
    const { currentStep } = this.state;

    const isIntroductionStepVisible = currentStep === INTRODUCTION_STEP;

    const isSuccessVisible = currentStep === SUCCESS_STEP;
    const isEmailFormVisible = currentStep === EMAIL_STEP;
    const isMessageFormVisible =
      currentStep === MESSAGE_STEP || isIntroductionStepVisible;
    const isButtonVisible = currentStep === CLOSED_STEP;
    const isHeaderVisible = isEmailFormVisible || isMessageFormVisible;

    const errorMessage = this.getStepErrorMessage();
    const hasError = !!errorMessage;

    const rootClassName = classNames('ask-now-container', {
      'ask-now-container--hidden': !isVisible,
      'ask-now-container--mobile-only': mobileOnly,
    });

    return (
      <div className={rootClassName}>
        <AskNowHeader
          onCancelClick={this.handleCancelClick}
          errorMessage={errorMessage}
          hasError={hasError}
          isVisible={isHeaderVisible}
        />

        <div className="ask-now">
          <AskNowStep isVisible={isSuccessVisible}>
            <Success />
          </AskNowStep>

          <AskNowStep
            onEntered={this.handleMessageStepEntered}
            isVisible={isMessageFormVisible}
          >
            <MessageForm
              onSubmit={this.handleMessageFormSubmit}
              onFieldChange={onFieldChange}
              onFieldFocus={this.handleMessageFieldFocus}
              fieldValue={messageValue}
              hasError={hasError}
              inputRef={this.messageInputRef}
            />
          </AskNowStep>

          <AskNowStep
            onEntered={this.handleEmailStepEntered}
            isVisible={isEmailFormVisible}
          >
            <EmailForm
              onFieldChange={onFieldChange}
              fieldValue={emailValue}
              onSubmit={this.handleEmailFormSubmit}
              hasError={hasError}
              inputRef={this.emailInputRef}
            />
          </AskNowStep>

          {isButtonVisible && (
            <button onClick={this.handleOpenClick} className="ask-now_button">
              ?
            </button>
          )}
        </div>
      </div>
    );
  }
}

function AskNowHeader({ isVisible, errorMessage, hasError, onCancelClick }) {
  return (
    <Transition mountOnEnter unmountOnExit timeout={500} in={isVisible}>
      {state => {
        const rootClassNames = classNames('ask-now_header', {
          'ask-now_header--entering': state === 'entering',
          'ask-now_header--entered': state === 'entered',
          'ask-now_header--exiting': state === 'exiting',
        });

        return (
          <div className={rootClassNames}>
            {hasError && <div className="ask-now_error">{errorMessage}</div>}

            <button
              type="button"
              onClick={onCancelClick}
              className="ask-now_close"
            >
              cancel
              <CloseIcon />
            </button>
          </div>
        );
      }}
    </Transition>
  );
}

function AskNowStep({ children, isVisible, onEntered }) {
  const timeout = { enter: 1000, exit: 500 };
  return (
    <Transition onEntered={onEntered} timeout={timeout} in={isVisible}>
      {state => {
        const rootClassNames = classNames('ask-now_step', {
          'ask-now_step--entered': state === 'entered',
          'ask-now_step--entering': state === 'entering',
          'ask-now_step--exiting': state === 'exiting',
        });

        return (
          <children.type
            {...children.props}
            isEntered={state === 'entered'}
            className={rootClassNames}
          />
        );
      }}
    </Transition>
  );
}

function Success({ className }) {
  return (
    <div className={className}>
      <div className="ask-now_content-container">
        <div className="ask-now_content ask-now_content--dark">
          Thank you for your question!
        </div>
      </div>

      <button className="ask-now_button">
        <CheckIcon />
      </button>
    </div>
  );
}

function EmailForm({
  onCancelClick,
  onSubmit,
  onFieldChange,
  fieldValue,
  className,
  hasError,
  inputRef,
}) {
  const inputClassNames = classNames('ask-now_input', {
    'ask-now_input--error': hasError,
  });

  return (
    <form onSubmit={onSubmit} className={className}>
      <div className="ask-now_input-container">
        <input
          onChange={onFieldChange}
          value={fieldValue}
          placeholder="Enter email, so we can respond to you"
          className={inputClassNames}
          name="email"
          ref={inputRef}
        />
      </div>

      <button className="ask-now_button">
        <SendIcon className="send-icon" />
      </button>
    </form>
  );
}

function MessageForm({
  onCancelClick,
  onSubmit,
  onFieldChange,
  fieldValue,
  className,
  hasError,
  inputRef,
  onFieldFocus,
}) {
  const inputClassNames = classNames('ask-now_input', {
    'ask-now_input--error': hasError,
  });

  return (
    <form onSubmit={onSubmit} className={className}>
      <div className="ask-now_input-container">
        <Textarea
          onChange={onFieldChange}
          value={fieldValue}
          placeholder="Question on the topic? Ask here."
          className={inputClassNames}
          name="message"
          maxRows={10}
          inputRef={inputRef}
          onFocus={onFieldFocus}
        />
      </div>

      <button className="ask-now_button">
        <SendIcon className="send-icon" />
      </button>
    </form>
  );
}

export default AskNowForm;
