import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'gatsby';

import AskNow from './ask-now';

import './footer.scss';

class Footer extends Component {
  static propTypes = {
    showAskNow: PropTypes.bool,
    askNowMobileOnly: PropTypes.bool,
    askNowPageIdentifier: PropTypes.string,
    className: PropTypes.string,
  };

  rootRef = createRef();

  state = {
    isAskNowVisible: true,
  };

  componentDidMount() {
    const { showAskNow } = this.props;

    if (showAskNow) {
      window.addEventListener('scroll', this.handleWindowScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
  }

  componentDidUpdate(prevProps) {
    const { showAskNow } = this.props;

    if (showAskNow !== prevProps.showAskNow) {
      if (showAskNow) {
        window.addEventListener('scroll', this.handleWindowScroll);
      } else {
        window.removeEventListener('scroll', this.handleWindowScroll);
      }
    }
  }

  maybeUpdateIsAskNowVisible(newValue) {
    const { isAskNowVisible } = this.state;

    if (isAskNowVisible !== newValue) {
      this.setState({
        isAskNowVisible: newValue,
      });
    }
  }

  getIsScrolledToFooter() {
    const pageScrollTop = window.pageYOffset + window.innerHeight;
    const pageHeight = document.documentElement.offsetHeight;
    const footerHeight = this.rootRef.current.offsetHeight;

    return pageHeight - pageScrollTop <= footerHeight;
  }

  getIsAskNowVisible() {
    const { showAskNow } = this.props;
    const { isAskNowVisible } = this.state;

    return showAskNow && isAskNowVisible;
  }

  handleWindowScroll = e => {
    const isScrolledToFooter = this.getIsScrolledToFooter();

    this.maybeUpdateIsAskNowVisible(!isScrolledToFooter);
  };

  render() {
    const { askNowPageIdentifier, className, askNowMobileOnly } = this.props;
    const isAskNowVisible = this.getIsAskNowVisible();

    const rootClassNames = classNames('footer', className);

    return (
      <footer ref={this.rootRef} className={rootClassNames}>
        <AskNow
          pageIdentifier={askNowPageIdentifier}
          isVisible={isAskNowVisible}
          mobileOnly={askNowMobileOnly}
        />
        <div className="copyright">
          ©2018 Trove. <br className="d-sm-none" /> All rights reserved
        </div>
        <nav className="footer_nav">
          <Link className="footer_nav_item" to="/faq">
            FAQ
          </Link>
          <Link className="footer_nav_item" to="/about">
            About
          </Link>
          <Link className="footer_nav_item" to="/contact-us">
            Contact Us
          </Link>
          <Link className="footer_nav_item" to="/terms-and-conditions">
            Terms of use
          </Link>
          <Link className="footer_nav_item" to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className="footer_nav_item" to="/disclaimer">
            Disclaimer
          </Link>
        </nav>
      </footer>
    );
  }
}

export default Footer;
