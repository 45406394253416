import { SITE_VISITED_EVENT_NAME } from 'constants/active-campaign';

const API_TRACKING_URL = '/.netlify/functions/event-tracking';

class ActiveCampaignTracking {
  async sendEvent(eventName, eventData) {
    const requestBody = {
      eventName,
      eventData,
    };

    await fetch(API_TRACKING_URL, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
  }

  async trackPageVisit() {
    const pageUrl = window.location.href;

    await this.sendEvent(SITE_VISITED_EVENT_NAME, pageUrl);
  }

  async clearSavedEmails() {
    await fetch(API_TRACKING_URL, {
      method: 'DELETE',
    });
  }
}

export default new ActiveCampaignTracking();
