import React from 'react';
import PropTypes from 'prop-types';

import { graphql, StaticQuery } from 'gatsby';

import ImageAsPlaceholder from 'components/common/image-as-background';

import './faq-placeholder.scss';

function PostFaqPlaceholder({ data, anchorName }) {
  const backgroundImage = data.backgroundImage.childImageSharp.fluid;

  return (
    <>
      <a href={`#${anchorName}`} className="hide-link-decoration">
        <span id={anchorName} className="heading-anchor" />
        <h1>Community Questions</h1>
      </a>
      <div className="post-page_faq-placeholder">
        <ImageAsPlaceholder image={backgroundImage} />
        <p className="post-page_faq-placeholder_title">
          You ask, we answer. Every Thursday, we answer and publish our
          community’s questions on this topic, right here
        </p>

        <p className="post-page_faq-placeholder_description">
          Questions about this? Or anything, really? Ask us by clicking the{' '}
          <span className="post-page_faq-placeholder_description_icon">?</span>{' '}
          to the right
        </p>
      </div>
    </>
  );
}

PostFaqPlaceholder.propTypes = {
  anchorName: PropTypes.string.isRequired,
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "faq-placeholder.png" }) {
          childImageSharp {
            fluid(maxWidth: 902) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <PostFaqPlaceholder data={data} {...props} />}
  />
);
