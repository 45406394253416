import React, { Component } from 'react';
import PropTypes from 'prop-types';

// lib
import ActiveCampaignTracking from 'lib/active-campaign-tracking';

// components
import AskNowForm from './ask-now-form';
import { ASKS_QUESTION_EVENT_NAME } from 'constants/active-campaign';

class AskNow extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    pageIdentifier: PropTypes.string,
    mobileOnly: PropTypes.bool,
  };

  static defaultProps = {
    pageIdentifier: '',
  };

  state = {
    isModalOpen: false,
    errors: {},
    isSendInProgress: false,
    isSentSuccess: false,
    email: '',
    message: '',
  };

  handleFieldChange = ({ target }) => {
    const { errors: preErrors } = this.state;
    const { name, value } = target;

    const errors = {
      ...preErrors,
      [name]: null,
      sendError: null,
    };

    this.setState({ [name]: value, errors });
  };

  handleAskNowOpen = () => {
    this.setState({
      isSentSuccess: false,
    });
  };

  handleFormSubmit = async () => {
    const { email, message } = this.state;

    await this.handleSubmitForm({
      email,
      message,
    });
  };

  handleSubmitForm = async formData => {
    if (this.state.isSendInProgress) return null;

    this.setState({ isSendInProgress: true, errors: {} });

    this.promiseSendFormData = fetch(`/.netlify/functions/ask-now`, {
      method: 'POST',
      body: JSON.stringify(formData),
    });

    const result = await this.promiseSendFormData;
    const body = await result.json();

    if (!result.ok) {
      const errors = body.errors || {
        errors: {
          sendError: 'Oops! Something wrong. Try to send later.',
        },
      };

      this.setState({
        isSendInProgress: false,
        errors,
      });

      return null;
    }

    ActiveCampaignTracking.sendEvent(ASKS_QUESTION_EVENT_NAME);

    this.setState({
      isSendInProgress: false,
      isSentSuccess: true,
      email: '',
      message: '',
    });
  };

  render() {
    const { isVisible, pageIdentifier, mobileOnly } = this.props;
    const {
      errors,
      isSendInProgress,
      isSentSuccess,
      message,
      email,
    } = this.state;

    return (
      <AskNowForm
        isVisible={isVisible}
        onSubmit={this.handleFormSubmit}
        errors={errors}
        isSendInProgress={isSendInProgress}
        isSentSuccess={isSentSuccess}
        messageValue={message}
        emailValue={email}
        onFieldChange={this.handleFieldChange}
        onOpen={this.handleAskNowOpen}
        pageIdentifier={pageIdentifier}
        mobileOnly={mobileOnly}
      />
    );
  }
}

export default AskNow;
