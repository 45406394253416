import React, { Component } from 'react';

// vendor components
import { graphql, StaticQuery } from 'gatsby';

// components
import CommonTeaser from 'components/common/teaser';
import ExternableLink from '../../common/externable-link';

// environment
const GET_ACCESS_LINK = process.env.GATSBY_GET_ACCESS_LINK;
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;

// constants
const DESCRIPTION_TEXT = `
  This article requires an Essential subscription. Join the Trove
  community to get access to this article and more professional resources
  in The Trove Library.
`;

// component class
class Teaser1EssentialLoginNeeded extends Component {
  // handleChoosePlanClick = () => {
  //   console.log('ChooseProPlanClick');
  // };

  render() {
    const { backgroundImage } = this.props.data;

    return (
      <CommonTeaser
        title="Want to know more?"
        description={DESCRIPTION_TEXT}
        caption="get access"
        to={GET_ACCESS_LINK || '/subscribe/pricing'}
        backgroundImage={(backgroundImage || {}).childImageSharp.fluid}
        isExternalLink={!!GET_ACCESS_LINK}
      >
        <span className=" font-italic">Already signed up? </span> Log in{' '}
        <ExternableLink
          to={LOGIN_LINK || '/subscribe/login'}
          isExternal={!!LOGIN_LINK}
        >
          here
        </ExternableLink>
      </CommonTeaser>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "teaser-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Teaser1EssentialLoginNeeded data={data} {...props} />}
  />
);
