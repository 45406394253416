const {
  GATSBY_ACTIVE_CAMPAIGN_SUBSCRIPTION_DATE_ID,
  GATSBY_ACTIVE_CAMPAIGN_LAST_LOGIN_DATE_ID,
  GATSBY_ACTIVE_CAMPAIGN_SUBSCRIPTION_TYPE_ID,
  GATSBY_ACTIVE_CAMPAIGN_SUBSCRIPTION_MODIFIED_ID,
  GATSBY_ACTIVE_CAMPAIGN_LOGIN_DATE_ID,
} = process.env;

export const SUBSCRIPTION_UPGRADE_EVENT_NAME = 'Subscription Upgrade';
export const SUBSCRIPTION_DOWNGRADE_EVENT_NAME = 'Subscription Downgrade';
export const SUBSCRIPTION_DOWNGRADE_PRO_MONTHLY_EVENT_NAME =
  'Downgrade Pro Monthly';
export const SUBSCRIPTION_DOWNGRADE_PRO_ANNUAL_EVENT_NAME =
  'Downgrade Pro Annual';
export const SUBSCRIPTION_UPGRADE_ESSENTIAL_MONTHLY_EVENT_NAME =
  'Upgrade Essential Monthly';
export const SUBSCRIPTION_UPGRADE_ESSENTIAL_ANNUAL_EVENT_NAME =
  'Upgrade Essential Annual';

export const SUBSCRIPTION_CANCELLATION_EVENT_NAME = 'Subscription Cancellation';
export const SUBSCRIPTION_CANCELLATION_PRO_MONTHLY_EVENT_NAME =
  'Cancellation Pro Monthly';
export const SUBSCRIPTION_CANCELLATION_PRO_ANNUAL_EVENT_NAME =
  'Cancellation Pro Annual';
export const SUBSCRIPTION_CANCELLATION_ESSENTIAL_MONTHLY_EVENT_NAME =
  'Cancellation Essential Monthly';
export const SUBSCRIPTION_CANCELLATION_ESSENTIAL_ANNUAL_EVENT_NAME =
  'Cancellation Essential Annual';

export const SIGN_UP_PRO_MONTHLY_EVENT_NAME = 'Customer Sign-up Pro - Monthly';
export const SIGN_UP_PRO_ANNUAL_EVENT_NAME = 'Customer Sign-up Pro - Annual';
export const SIGN_UP_ESSENTIAL_MONTHLY_EVENT_NAME =
  'Customer Sign-up Essential - Monthly';
export const SIGN_UP_ESSENTIAL_ANNUAL_EVENT_NAME =
  'Customer Sign-up Essential - Annual';
export const RESUBSCRIBE_PRO_EVENT_NAME = 'Customer Resubscribe Pro';
export const RESUBSCRIBE_ESSENTIAL_EVENT_NAME =
  'Customer Resubscribe Essential';
export const EMAIL_SIGNUP_EVENT_NAME = 'Email Sign-up';
export const LOGIN_EVENT_NAME = 'Login';
export const CONTACT_US_SUBMIT_EVENT_NAME = 'Contact Us Submit';
export const ASKS_QUESTION_EVENT_NAME = 'Asks a Question';
export const CLICKS_ON_PAID_CONTENT =
  'Clicks on paid plan content in the library';
export const CLICKS_ON_PRO_CONTENT = 'Clicks on Pro Content';
export const FREE_TRIAL_EVENT_NAME = 'Free 7-day trial';
export const TRIAL_ENDS_EVENT_NAME =
  'Moves from Free Trial to Paid Subscription';
export const SITE_VISITED_EVENT_NAME = 'Site Visited';
export const ACCEPTED_NEWSLETTER = 'Accepted Newsletter';

export const SUBSCRIPTION_DATE_FIELD_ID = GATSBY_ACTIVE_CAMPAIGN_SUBSCRIPTION_DATE_ID;
export const LAST_LOGIN_DATE_FIELD_ID = GATSBY_ACTIVE_CAMPAIGN_LAST_LOGIN_DATE_ID;
export const SUBSCRIPTION_TYPE_FIELD_ID = GATSBY_ACTIVE_CAMPAIGN_SUBSCRIPTION_TYPE_ID;
export const SUBSCRIPTION_MODIFIED_FIELD_ID = GATSBY_ACTIVE_CAMPAIGN_SUBSCRIPTION_MODIFIED_ID;
export const LOGIN_DATE_FIELD_ID = GATSBY_ACTIVE_CAMPAIGN_LOGIN_DATE_ID;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const SUBSCRIPTION_TYPE_FREE = 'Free';
export const SUBSCRIPTION_TYPE_NONE = 'None';
