import React, { Component } from 'react';
import PropTypes from 'prop-types';

// vendor components
import { graphql, StaticQuery } from 'gatsby';

// components
import CommonTeaser from 'components/common/teaser';
import ExternableLink from '../../common/externable-link';

// constants
const DESCRIPTION_TEXT = `
   This article requires a Pro subscription. Join the Trove
   community to get access to this article and more professional resources
   in The Trove Library.
`;

// environment
const LOGIN_LINK = process.env.GATSBY_LOGIN_LINK;

// component class
class Teaser2ProLoginNeeded extends Component {
  static propTypes = {
    onChoosePlanClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isPlanModalVisible: false,
    };
  }

  render() {
    const { onChoosePlanClick, data } = this.props;
    const { backgroundImage } = data;

    return (
      <CommonTeaser
        title="Want to know more?"
        description={DESCRIPTION_TEXT}
        caption="Get Access"
        onClick={onChoosePlanClick}
        backgroundImage={(backgroundImage || {}).childImageSharp.fluid}
      >
        Already signed up? Log in{' '}
        <ExternableLink
          isExternal={!!LOGIN_LINK}
          to={LOGIN_LINK || '/subscribe/login'}
        >
          here
        </ExternableLink>
      </CommonTeaser>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "teaser-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Teaser2ProLoginNeeded data={data} {...props} />}
  />
);
