import React, { Component } from 'react';

import ActiveCampaignTracing from './active-campaign-tracking';

function trackPageVisit(WrappedComponent) {
  return class extends Component {
    componentDidMount() {
      ActiveCampaignTracing.trackPageVisit();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default trackPageVisit;
