import React from 'react';
import PropTypes from 'prop-types';

// components
import Button from 'components/common/button';
import ImageAsBackground from 'components/common/image-as-background';

import './teaser.scss';

// component function
function CommonTeaser({
  title,
  description,
  caption,
  to,
  onClick,
  children,
  backgroundImage,
  hideGradient,
  isExternalLink,
}) {
  return (
    <div className="resource-library_teaser">
      {!hideGradient && <div className="resource-library_teaser_gradient" />}
      <ImageAsBackground image={backgroundImage} />

      <div className="resource-library_teaser_content">
        <h3 className="resource-library_teaser_title">{title}</h3>
        <p className="resource-library_teaser_description">{description}</p>
        {!!children && <p className="font-weight-light">{children}</p>}

        <Button
          className="resource-library_teaser_button"
          useLink={!!to}
          to={to}
          onClick={onClick}
          isExternalLink={isExternalLink}
        >
          {caption}
        </Button>
      </div>
    </div>
  );
}

CommonTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  backgroundImage: PropTypes.object.isRequired,
  hideGradient: PropTypes.bool,
  isExternalLink: PropTypes.bool,
};

export default CommonTeaser;
