import React from 'react';

// components
import CommonTeaser from 'components/common/teaser';
import { graphql, StaticQuery } from 'gatsby';

// constants
const DESCRIPTION_TEXT = `
  Join our community right now for $99.99/month to read this article and get access to professional resources on TROVE
`;

// component class
function Teaser3ProNeeded({ data }) {
  const { backgroundImage } = data;

  return (
    <CommonTeaser
      title="Want to read this article?"
      description={DESCRIPTION_TEXT}
      caption="upgrade my plan"
      to="/subscribe/profile"
      backgroundImage={(backgroundImage || {}).childImageSharp.fluid}
    />
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "teaser-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Teaser3ProNeeded data={data} {...props} />}
  />
);
