import React from 'react';
import PropTypes from 'prop-types';

// components
import TextSpoiler from 'components/common/text-spoiler';
import PostFaqPlaceholder from 'components/post/faq-placeholder';

// styles
import './frequently-asked-questions.scss';

// component function
function FrequentlyAskedQuestions({ items, anchorName, showPlaceholder }) {
  if (!items || !items.length) {
    if (!showPlaceholder) {
      return null;
    }

    return <PostFaqPlaceholder anchorName={anchorName} />;
  }

  return (
    <div className="resource-library_faq">
      <a href={`#${anchorName}`} className="hide-link-decoration">
        <span id={anchorName} className="heading-anchor" />
        <h1>Community Questions</h1>
      </a>
      <>
        {items.map(item => (
          <TextSpoiler
            key={item.id}
            className="post-page_text-spoiler"
            title={item.question}
            text={((item.answer || {}).childMarkdownRemark || {}).html}
          />
        ))}
      </>
    </div>
  );
}

FrequentlyAskedQuestions.propTypes = {
  anchorName: PropTypes.string.isRequired,
  items: PropTypes.array,
};

export default FrequentlyAskedQuestions;
