import React from 'react';
import PropTypes from 'prop-types';

// components
import Footer from './footer';

// vendor components & lib
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

// styles
import 'stylesheets/global/index.scss';

const Layout = ({
  children,
  showAskNow,
  askNowMobileOnly,
  title,
  data,
  askNowPageIdentifier,
  footerClassName,
  pageImageSrc,
  pageDescription,
}) => {
  const titleFromMetadata = data.site.siteMetadata.title;

  const titleTemplate = `%s - ${titleFromMetadata}`;

  const defaultDescription = 'The influencer business bible.';
  const defaultImage = data.defaultSiteImage.childImageSharp.fixed.src;

  const metaDescription = pageDescription || defaultDescription;
  const metaImage = pageImageSrc || defaultImage;

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={titleTemplate}
        defaultTitle={titleFromMetadata}
        meta={[
          { name: 'description', content: metaDescription },
          { name: 'og:description', content: metaDescription },
          { name: 'og:image', content: metaImage },
          { name: 'og:title', content: title || titleFromMetadata },
        ]}
      >
        <html lang="en" />
        <script src="https://js.stripe.com/v3/" />
      </Helmet>
      <div className="root">
        <div className="flex-grow-1">{children}</div>
        <Footer
          className={footerClassName}
          askNowPageIdentifier={askNowPageIdentifier}
          showAskNow={showAskNow}
          askNowMobileOnly={askNowMobileOnly}
        />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showAskNow: PropTypes.bool,
  askNowMobileOnly: PropTypes.bool,
  askNowPageIdentifier: PropTypes.string,
  title: PropTypes.string,
  footerClassName: PropTypes.string,
  pageImageSrc: PropTypes.string,
  pageDescription: PropTypes.string,
};

export default props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        defaultSiteImage: file(relativePath: { eq: "home-logo.png" }) {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Layout {...props} data={data} />}
  />
);
