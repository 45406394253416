import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from 'gatsby';

import './sidebar.scss';

const PostPageSidebar = forwardRef(({ items, className, hide }, ref) => {
  if (!items || !items.length) {
    return <div ref={ref} />;
  }

  const rootClassNames = classNames(className, 'post-page_sidebar', {
    'd-none': hide,
  });

  return (
    <div ref={ref} className={rootClassNames}>
      {(items || []).map((item, index) => (
        <SidebarItem
          key={index}
          index={index}
          linkUrl={item.url}
          anchorUrl={`#${item.slug}`}
        >
          {item.text}
        </SidebarItem>
      ))}
    </div>
  );
});

PostPageSidebar.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  hide: PropTypes.bool,
};

function SidebarItem({ children, index, anchorUrl, linkUrl }) {
  return (
    <div className="post-page_sidebar_item">
      <span className="post-page_sidebar_position">{index + 1}.</span>{' '}
      {!!linkUrl ? (
        <Link
          className="post-page_sidebar_link"
          to={linkUrl}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      ) : (
        <a
          className="post-page_sidebar_link"
          href={anchorUrl}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      )}
    </div>
  );
}

export default PostPageSidebar;
